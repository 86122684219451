import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import foodStyles from "./food.module.css"
import eventStyles from "./events.module.css"

import headerImage from "../images/wood_header.jpg"

const EventPage = ({ data }) => {
  const events = data.events.nodes;
  const eventDates = data.eventDates.nodes;
  const eventWeekly = data.eventWeekly.nodes;

  eventDates.forEach(e => {
    e.frontmatter.eventDate = new Date(e.frontmatter.eventDate);
  });

  eventDates.sort((a, b) => a.frontmatter.eventDate - b.frontmatter.eventDate);

  const todayDate = new Date();

  const foundDate = eventDates.find(e => e.frontmatter.eventDate >= todayDate || isSameDay(e.frontmatter.eventDate, todayDate));
  const foundDateIndex = eventDates.indexOf(foundDate);
  let fiveEventDates = null;
  if (foundDateIndex > -1) {
    fiveEventDates = eventDates.slice(foundDateIndex, foundDateIndex + 4);
  }

  return (
    <Layout>
      <SEO title="Events" />
      <img src={headerImage} className={foodStyles.eventBackgroundImage} alt="Hanging lights." />
      <div className={eventStyles.eventBackgroundColor}></div>
      <h1 className={foodStyles.title}>EVENTS</h1>
      <div className={eventStyles.eventSection}>
        {fiveEventDates != null && <>
          <h2 className={eventStyles.eventHeader}>Featured Events</h2>
          <div className={eventStyles.eventLists}>
            {fiveEventDates.map(date => {
              const event = events.find(event => { return event.frontmatter.title === date.frontmatter.event });
              const eventDate = date.frontmatter.eventDate;

              return (
                <div className={eventStyles.event}>
                  <img src={event.frontmatter.eventImage.publicURL} className={eventStyles.eventImage} alt={date.frontmatter.event} />
                  <div className={eventStyles.eventDetails}>
                    <p className={eventStyles.eventDate}>{eventDate.toLocaleDateString('en-US', { month: 'numeric', day: 'numeric', weekday: 'long' })}: <span className={eventStyles.eventTime}>{date.frontmatter.startTime} - {date.frontmatter.endTime}</span></p>
                    <a className={eventStyles.eventTitle} href={event.frontmatter.link} alt="Link to event." target="_blank" rel="noreferrer noopener">{date.frontmatter.event}</a>
                    <p className={eventStyles.eventDescription}>{event.frontmatter.description}</p>
                    {(event.frontmatter.link != null && event.frontmatter.link != "") && <a className={eventStyles.eventLink} href={event.frontmatter.link} alt="Link to event." target="_blank" rel="noreferrer noopener">See More</a>}
                  </div>
                </div>
              )
            })}
          </div>
        </>}
        <h2 className={eventStyles.eventHeader}>Weekly Events</h2>
        <div className={eventStyles.eventLists}>
          {eventWeekly.map(date => {
            const event = events.find(event => { return event.frontmatter.title === date.frontmatter.event });

            return (
              <div className={eventStyles.event}>
                <img src={event.frontmatter.eventImage.publicURL} className={eventStyles.eventImage} alt={date.frontmatter.event} />
                <div className={eventStyles.eventDetails}>
                  <p className={eventStyles.eventDate}>Every {date.frontmatter.eventDay}: <span className={eventStyles.eventTime}>{date.frontmatter.startTime} - {date.frontmatter.endTime}</span></p>
                  <a className={eventStyles.eventTitle} href={event.frontmatter.link} alt="Link to event." target="_blank" rel="noreferrer noopener">{date.frontmatter.event}</a>
                  <p className={eventStyles.eventDescription}>{event.frontmatter.description}</p>
                  {/* <a className={eventStyles.eventLink} href={event.frontmatter.link} alt="Link to event." target="_blank" rel="noreferrer noopener">See More</a> */}
                  {(event.frontmatter.link != null && event.frontmatter.link != "") && <a className={eventStyles.eventLink} href={event.frontmatter.link} alt="Link to event." target="_blank" rel="noreferrer noopener">See More</a>}
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <Img fluid={data.beerCollectionImage.childImageSharp.fluid} alt="" className={foodStyles.beerCollectionImage} />
    </Layout>
  )
}

const isSameDay = (a, b) => {
  return a.getFullYear() === b.getFullYear() &&
    a.getMonth() === b.getMonth() &&
    a.getDate() === b.getDate()
}

export default EventPage

export const query = graphql`
  query {
    beerCollectionImage: file(relativePath: { eq: "beer_collection.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    eventDates: allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "event-date"}}}, sort: {fields: frontmatter___eventDate, order: ASC}) {
      nodes {
          frontmatter {
              eventDate
              event
              startTime
              endTime
          }
      }
    }
    eventWeekly: allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "event-weekly"}}}) {
      nodes {
          frontmatter {
              eventDay
              event
              startTime
              endTime
          }
      }
    }
    events: allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "events"}}}) {
        nodes {
          frontmatter {
            title
            description
            link
            eventImage {
              publicURL
            }
          }
        }
    }
  }
`